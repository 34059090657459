





























import Vue from "vue";
import fb from "@/plugins/firebase";
import "@/types";

export default Vue.extend({
  data() {
    return {
      items: [] as News[]
    };
  },

  methods: {
    async getLatestNews() {
      const query = await fb
        .firestore()
        .collection("/apps/kahev-org/news-en")
        .where("disabled", "==", false)
        .orderBy("dateCreated", "desc")
        .limit(10)
        .get();

      if (!query.empty) {
        query.docs.forEach(doc => {
          const item = {
            id: doc.id,
            urlCode: doc.data().urlCode,
            title: doc.data().title,
            text: doc.data().text,
            previewImg: doc.data().previewImg,
            dateCreated: doc.data().dateCreated.toDate(),
            dateUpdated: doc.data().dateUpdated?.toDate()
          } as News;

          if (this.$route.params.urlCode != item.urlCode) {
            this.items.push(item);
          }
        });
      }
    }
  },

  async mounted() {
    await this.getLatestNews();
  }
});
